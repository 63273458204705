<template>
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <div class="p-grid p-holder">
        <Fieldset legend="Datos personales" :toggleable="true" class="p-col-12">
            <div class="p-grid">
                <div class="p-col-12 p-md-6">
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="ced" type="text" v-model="data.dni"
                                        :class="{'p-invalid': submitted && !data.dni }" class="p-inputtext-sm"
                                        :disabled="!edit"/>
                            <label for="ced">Cédula</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.dni">La cédula es requerida</small>
                    </div>
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="name" type="text" v-model="data.name"
                                        :class="{'p-invalid': submitted && !data.name }" class="p-inputtext-sm"
                                        :disabled="!edit"/>
                            <label for="name">Nombres</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.name">Los nombres son requeridos</small>
                    </div>
                    <div class="p-field">
                        <span class="p-float-label">
                            <input-text id="last_name" type="text" v-model="data.last_name"
                                        :class="{'p-invalid': submitted && !data.last_name }" class="p-inputtext-sm"
                                        :disabled="!edit"/>
                            <label for="last_name">Apellidos</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.last_name">Los apellidos son requeridos</small>
                    </div>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="p-field p-fluid element">
                                <span class="p-float-label">
                                     <Calendar id="to" :showIcon="true" v-model="data.birth_date"  dateFormat="dd/mm/yy"  :yearNavigator="true" yearRange="1920:2050" />
                                    <label for="to">Fecha  de nacimiento</label>
                                </span>
                    </div>

                    <div class="p-field">
                        <span class="p-float-label">
                            <InputText id="mail" type="text" v-model="data.email" name="email"
                                       :class="{'p-invalid': submitted && !data.email}" class="p-inputtext-sm"
                                       :disabled="!edit"/>
                            <label for="mail">Email</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.email">El email es requerido</small>
                    </div>

                    <div class="p-field">
                        <span class="p-float-label">
                            <InputText id="phone" type="text" v-model="data.phone"
                                       :class="{'p-invalid': submitted && !data.phone}" class="p-inputtext-sm"
                                       :disabled="!edit"/>
                            <label for="mail">Teléfono</label>
                        </span>
                        <small class="p-invalid" v-if="submitted && !data.phone">El teléfono es requerido</small>
                    </div>
                </div>
                <div class="p-col-12">
                    <div class="p-field p-fluid p-p-0">
                        <span class="p-float-label">
                            <Textarea id="observation" v-model="data.address" class="p-inputtext-sm" rows="2"/>
                            <label for="observation">Dirección</label>
                        </span>
                    </div>
                </div>
            </div>
        </Fieldset>
    </div>

</template>

<script>
    import service from '../../service/student.service'
    import moment from "moment";

    export default {
        name: "Student",
        props: {
            enroll: Object,
            current: Object,
            edit: Boolean,
        },
        emits: ['ok', 'cancel'],
        data() {
            return {
                data: {},
                submitted: false,
                loading: false
            }
        },
        methods: {
            formatData() {
                const params = {...this.data};
                params.enrollment_id = this.enroll.id;
                params.birth_date = this.$filters.dateFormat(params.birth_date);
                return params;
            },
            save() {
                return new Promise((resolve, reject) =>  {
                    this.submitted = true;
                    if (!this.data.name || !this.data.last_name || !this.data.dni || !this.data.email || !this.data.phone) {
                        reject();
                        return null;
                    }
                    this.loading = true;
                    const params = this.formatData();
                    service.save(params, this.data.id).then(() => {
                        this.loading = false;
                        resolve();
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Registrado correctamente',
                            life: this.$utils.toastLifeTime()
                        })
                    }).catch((error) => {
                        reject(error);
                        this.loading = false;
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error en el registro de datos',
                            life: this.$utils.toastLifeTime()
                        })
                    })
                })
            }
            ,
            cancel() {
                this.$emit('cancel')
            }
        },
        mounted() {
            this.data = this.enroll && this.current ? this.current : {};
            this.data.birth_date = new Date(moment(this.data.birth_date).format());
        }
    }
</script>

<style scoped lang="scss">

    .p-fieldset {
        margin-top: 15px;
    }

    .p-field {
        padding-top: 15px;
    }

    .p-dialog-footer {
        padding: 0;
        margin-bottom: -15px;
    }

    .p-grid {
        margin-top: 15px;
    }

    .p-inputtext {
        width: 100%;
    }
    .p-autocomplete {
        height: 30px !important;
    }
</style>
